// 2023-04-01
import React, { useState, useEffect, useRef } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { getRandomWord } from "../lib/getRandomWord.js";

const AVERAGE_WORD_LENGTH = 5;

/*
put the excerpt in a text box.
This text box only displays 2-3 lines at a time but scrolls based on how much they've typed.


The user types in a textarea / input.
As they type, it highlights how much of the word they got correct in the excerpt box.
So if they've typed the first 10 characters correctly, those characters are highlighted green.
If they've then typed the next 3 characters incorrectly, those characters are highlighted red.
And untyped characters are not highlighted, they just stay black.

We need to do a few things for the excerpt box:
1. render each character in a <span> with a unique id.
2. diff the excerpt with typed, and style each character differently based on how much is typed.
3. (every character?) scroll to the last highlighted character (red or green).

*/

// write our own useDate function, since we do not need it aligned to 0ms
const useDate = ({ interval = 1000 } = {}) => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, interval);
    return () => clearInterval(intervalId);
  }, [interval]);
  return date;
};

const MobileTypingSpeedTest = () => {
  const excerptRef = useRef(null);
  if (!excerptRef.current) {
    const randomWords = [];
    for (let i = 0; i < 30; i++) {
      randomWords.push(getRandomWord());
    }
    excerptRef.current = randomWords.join(" ");
  }
  const excerpt = excerptRef.current;

  const [typed, setTyped] = useState("");
  const diff = diffExcerpt(excerpt, typed);
  const excerptHighlighted = [];
  const lastHighlighted = useRef(null);
  for (let i = 0; i < excerpt.length; i++) {
    excerptHighlighted.push(
      <span
        key={i}
        ref={i === typed.length - 1 ? lastHighlighted : null}
        style={{
          color:
            diff[i] === "correct"
              ? "green"
              : diff[i] === "incorrect"
              ? "red"
              : "black",
        }}
      >
        {excerpt[i]}
      </span>,
    );
  }
  useEffect(() => {
    // scroll to the last highlighted character
    if (lastHighlighted.current) {
      lastHighlighted.current.scrollIntoView();
    }
  }, [typed]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  useEffect(() => {
    if (typed.length === 1 && !startTime) {
      setStartTime(new Date());
    }
    if (
      typed.trim().toLowerCase() === excerpt.trim().toLowerCase() &&
      !endTime
    ) {
      setEndTime(new Date());
      if (typedTextareaRef.current) {
        typedTextareaRef.current.blur();
      }
    }
  }, [typed, excerpt, startTime, endTime]);

  const now = useDate();
  let elapsedParagraph = null;
  if (startTime) {
    let elapsed;
    if (endTime) {
      elapsed = (endTime - startTime) / 1000;
    } else if (now < startTime) {
      // useDate() updates every 1 second but hasn't updated yet
      elapsed = 0;
    } else {
      elapsed = (now - startTime) / 1000;
    }
    const elapsedMinutes = Math.floor(elapsed / 60);
    const elapsedSeconds = Math.floor(elapsed % 60);
    const estimatedWordsPerMinute =
      elapsed === 0
        ? 0
        : Math.floor(
            (typed.trim().length / AVERAGE_WORD_LENGTH / elapsed) * 60,
          );

    const reset = () => {
      // reset all state
      setTyped("");
      setStartTime(null);
      setEndTime(null);
      // reset all refs
      lastHighlighted.current = null;
      excerptRef.current = null;
      // focus the textarea so they're ready to type
      if (typedTextareaRef.current) {
        typedTextareaRef.current.focus();
      }
    };

    elapsedParagraph = (
      <p>
        <button onClick={reset}>Reset</button>
        {" "}
        {`${String(elapsedMinutes).padStart(2, "0")}:${String(
          elapsedSeconds,
        ).padStart(2, "0")}`}
        {" / "}
        {estimatedWordsPerMinute} wpm {endTime ? "✅" : ""}{" "}
      </p>
    );
  }

  const typedTextareaRef = useRef(null);

  return (
    <Layout>
      <SEO title="Mobile Typing Speed Test"></SEO>
      <p>How fast can you type?</p>
      <p>Excerpt:</p>
      {/* display elapsed time relative to `now`. Display as mm:ss */}
      <div
        style={{
          maxWidth: "300px",
          overflowY: "scroll",
          height: "100px",
          border: "1px solid black",
          padding: "5px",
        }}
      >
        {excerptHighlighted}
      </div>
      <p>Typed:</p>
      <textarea
        ref={typedTextareaRef}
        value={typed}
        readOnly={!!endTime}
        style={{
          width: "300px",
          height: "100px",
          border: "1px solid black",
          padding: "5px",
        }}
        onChange={e => setTyped(e.target.value)}
      ></textarea>
      {elapsedParagraph}
    </Layout>
  );
};

// returns an array the same length as excerpt, where each entry is "correct", "incorrect", or "untyped"
const diffExcerpt = (excerpt, typed) => {
  const result = [];
  for (let i = 0; i < excerpt.length; i++) {
    if (i >= typed.length) {
      result.push("untyped");
    } else if (typed[i].toLowerCase() === excerpt[i].toLowerCase()) {
      result.push("correct");
    } else {
      result.push("incorrect");
    }
  }
  return result;
};

export default MobileTypingSpeedTest;
